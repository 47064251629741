<template>
    <div class="input-group">
        <input class="form-control form-control-sm" :placeholder="$t('Search') + '...'" v-model="searchInput" type="search" @search="doSearch"/>
        <button type="button" class="btn btn-sm btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><span class="visually-hidden">Bytt rullegardinmenyen</span></button>
        <div class="dropdown-menu">
            <div class="form-check">
                <label>
                    <input class="form-check-input" v-model="searchType" type="radio" name="fullTextSearchType" value="Contains">
                    {{$t('Contains')}}
                </label>
            </div>
            <div class="form-check">
                <label>
                    <input class="form-check-input" v-model="searchType" type="radio" name="fullTextSearchType" value="Contains_All_Words">
                    {{$t('Contains All Words')}}
                </label>
            </div>
            <div class="form-check">
                <label>
                    <input class="form-check-input" v-model="searchType" type="radio" name="fullTextSearchType" value="Contains_Any_Word">
                    {{$t('Contains Any Word')}}
                </label>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">

import type DataObject from 'o365.modules.DataObject.ts';
import { ref, watch } from 'vue';

const props = defineProps<{
    searchFunction: String,
    dataObject: DataObject
}>();

const searchInput = ref("");

const searchType = ref("Contains_All_Words");

function escapeFullTextString(str) {
    return `"${str.replaceAll('"', '""')}*"`
}

function splitAndEscapeFullTextString(str, keyword) {
    const words = str.split(' ').filter(s => s);

    return words.map(escapeFullTextString).join(` ${keyword} `)
}

function getFullTextSearchString(searchString, searchType){
    if (searchType == "Contains") {
        return escapeFullTextString(searchString);
    } else if (searchType == "Contains_All_Words") {
        return splitAndEscapeFullTextString(searchString, 'AND');
    } else if (searchType == "Contains_Any_Word") {
        return splitAndEscapeFullTextString(searchString, 'OR');
    } else {
        return searchString;
    }
}

let debouncedSearch : undefined | number;

function doSearch() {
    clearTimeout(debouncedSearch);
    props.dataObject.dataSearch(getFullTextSearchString(searchInput.value, searchType.value), props.searchFunction);
}


watch(searchInput, str => {
    clearTimeout(debouncedSearch);
    debouncedSearch = setTimeout(() => {
        doSearch();
    }, 500);
});

watch(searchType, str => {
    doSearch();
});

</script>
